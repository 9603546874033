<template lang="pug">
q-form(
  @submit="onSubmit",
  style="margin-top: -35px",
  @validation-error="validationError"
)
  q-breadcrumbs.q-mt-xl.q-pt-lg.q-pa-dynamic(gutter="xs")
    template(v-slot:separator)
      q-icon(size="1.3em", :name="matChevronRight", color="primary")
    q-breadcrumbs-el.text-dark(:label="$t('home')", to="/")
    q-breadcrumbs-el.text-dark(:label="$t('academy.title')", to="/submission")
    q-breadcrumbs-el.text-primary(:label="$t('submit')")

  .row.justify-center.q-col-gutter-sm.q-pt-xl.relative-position.q-pa-dynamic(
    v-if="config"
  )
    .col-sm-9.col-md-8.col-12
      .text-h1.text-center.text-bold.q-mb-xl {{ $t(`phases.${phase}.title`) }}

      //- .text-h2.text-center {{ $t('your-submission') }}

      PhaseSubmission(
        ref="formInput",
        :phase="phase",
        :files="files",
        :config="config",
        :submission="submission",
        v-on:rejected="onRejected"
      )

  .q-ma-dynamic
    .row.justify-center.q-col-gutter-sm.q-pb-xl(v-if="config")
      .col-sm-9.col-md-8.col-12
        div
          //- metadata
          .text-center.q-my-lg.q-pt-xl
            //- q-icon(:name="matInfo", size="lg")
            .text-h2 {{ $t("information") }}

            //- FOR SUBMIT PHASE - COLLECT WHATSAPP INFO
            .q-mt-xl(v-if="collectWhatsApp")
              .row.items-center(
                :class="{ dimout: submission.formdata.nonwhatsapp }"
              )
                .col-5
                  .q-mb-md(style="position: relative")
                    q-select(
                      lazy-rules,
                      :readonly="submission.formdata.nonwhatsapp",
                      outlined,
                      v-model="submission.formdata.telCode",
                      :options="filteredCodes",
                      map-options,
                      emit-value,
                      option-value="dialCode",
                      use-input,
                      @filter="filterDialCode",
                      :rules="[(val) => validateWhatsAppCode(val) || this.$t('requires-telcode')]"
                    )
                    .floatinglabel
                      .row
                        .col-auto
                          .bg-lilac.q-pa-xs.text-dark Country
                .col.q-pl-sm
                  FormInput(
                    v-model="submission.formdata.whatsapp",
                    :readonly="submission.formdata.nonwhatsapp",
                    mask="############",
                    :name="this.$t('whatsapp-number')",
                    :rules="[(val) => validateWhatsApp(val) || this.$t('requires-whatsapp-number')]"
                  )
              .row.q-mb-lg(style="margin-top: -20px")
                .col-12
                  q-checkbox(
                    v-model="submission.formdata.nonwhatsapp",
                    :label="this.$t('i-dont-have-a-whatsapp-account')"
                  )

                .col-12(v-show="submission.formdata.nonwhatsapp")
                  .row.q-mt-md
                    .col-12.q-mb-lg(style="position: relative")
                      q-field(
                        outlined,
                        :rules="[(val) => validateService(val) || $t('service-required')]",
                        v-model="submission.formdata.alternate"
                      )
                        template(v-slot:control)
                          q-option-group.q-mt-md(
                            v-model="submission.formdata.alternate",
                            :options="alternativeWA"
                          )
                      .floatinglabel
                        .row
                          .col-auto
                            .bg-lilac.q-pa-xs.text-dark {{ $t("select-a-service-you-use") }}
                    .col-12
                      FormInput(
                        v-model="submission.formdata.nonwhatsappident",
                        :name="this.$t('user-identifier')",
                        :rules="[(val) => validateNonWhatsApp(val) || this.$t('identifier-required')]"
                      )

          .q-mb-md(style="position: relative")
            .relative-position
              q-input(
                v-model="submission.formdata.age",
                outlined,
                type="number",
                :rules="[(val) => val > 10 || this.$t('requires_age')]"
              )
              .floatinglabel
                .row
                  .col-auto
                    .bg-lilac.q-pa-xs.text-dark {{ $t("team_age") }}

          .q-mb-lg(style="position: relative")
            q-select.full-width(
              outlined,
              v-model="submission.language",
              emit-value,
              map-options,
              :options="recordingLangs",
              :rules="[(val) => !!val || $t('language_required')]"
            )
            .floatinglabel
              .row
                .col-auto
                  .bg-lilac.q-pa-xs.text-dark {{ $t("language_recorded_in") }}

          .q-mb-lg(style="position: relative")
            q-field.full-width(
              outlined,
              ref="onetag",
              v-model="submission.oneTag",
              :rules="[(val) => !!val || $t('onetag_required')]"
            )
              .q-py-md
                q-chip.q-pl-md.q-pr-sm(
                  :key="tag.value",
                  size="md",
                  v-for="tag of tagOptions",
                  clickable,
                  :selected="submission.oneTag === tag.value",
                  @click="submission.oneTag = tag.value",
                  :outline="submission.oneTag !== tag.value",
                  :dark="submission.oneTag === tag.value",
                  color="primary",
                  text-color="white"
                ) {{ tag.label }}
            .floatinglabel
              .row
                .col-auto
                  .bg-lilac.q-pa-xs.text-dark {{ $t("one_tag") }}

          .q-mb-lg(style="position: relative")
            q-field.full-width(
              outlined,
              ref="tags",
              lazy-rules,
              :rules="[(val) => hasTags || $t('tag_required')]"
            )
              .q-py-md
                q-chip.q-pl-md.q-pr-sm(
                  :key="tag.value",
                  size="md",
                  v-for="tag of tagOptionsSecondary",
                  :selected.sync="selectedTags[tag.value]",
                  :outline="!selectedTags[tag.value]",
                  :dark="selectedTags[tag.value]",
                  color="primary",
                  text-color="white"
                ) {{ tag.label }}
            .floatinglabel
              .row
                .col-auto
                  .bg-lilac.q-pa-xs.text-dark {{ $t("tag_label") }}

          .q-mb-lg(style="position: relative")
            q-select.full-width(
              outlined,
              v-model="submission.region",
              emit-value,
              map-options,
              :options="regions",
              :rules="[(val) => !!val || $t('country_required')]"
            )
              template(v-slot:selected-item="scope")
                q-item(v-bind="scope.itemProps", v-on="scope.itemEvents")
                  q-item-section.flag(avatar) {{ scope.opt.flag }}
                  q-item-section
                    q-item-label {{ scope.opt.label }}

              template(v-slot:option="scope")
                q-item(v-bind="scope.itemProps", v-on="scope.itemEvents")
                  q-item-section.flag(avatar) {{ scope.opt.flag }}
                  q-item-section
                    q-item-label {{ scope.opt.label }}
            .floatinglabel
              .row
                .col-auto
                  .bg-lilac.q-pa-xs.text-dark {{ $t("country_im_in") }}

          .q-mb-lg(style="position: relative")
            q-select.full-width(
              outlined,
              v-model="submission.commslanguage",
              emit-value,
              map-options,
              :options="commsLangs",
              :rules="[(val) => !!val || $t('communication_language_required')]"
            )
            .floatinglabel
              .row
                .col-auto
                  .bg-lilac.q-pa-xs.text-dark {{ $t("language_we_will_use_when_messaging_you") }}

        div(v-if="collectReporting")
          .text-center.q-mb-xl
            .text-h2 {{ $t("program-details") }}

          .q-mb-md(style="position: relative")
            FormInput(
              :name="$t('project_name')",
              v-model="submission.formdata.projectname",
              :rules="[(val) => val.length || this.$t('more-text-is-required')]"
            )
          .q-mb-md(style="position: relative")
            FormInput(
              type="textarea",
              :name="$t('project_description')",
              v-model="submission.formdata.projectdescription",
              :rules="[(val) => val.length || this.$t('more-text-is-required')]"
            )
          .q-mb-md(style="position: relative")
            FormInput(
              type="textarea",
              :name="$t('testemony_team')",
              v-model="submission.formdata.testemony_team",
              :rules="[(val) => val.length || this.$t('more-text-is-required')]"
            )
          .q-mb-md(style="position: relative")
            FormInput(
              type="textarea",
              :name="$t('testemony_beneficiary')",
              v-model="submission.formdata.testemony_beneficiary",
              :rules="[(val) => val.length || this.$t('more-text-is-required')]"
            )
          .q-mb-md(style="position: relative")
            FormInput(
              :name="$t('link_photos')",
              v-model="submission.formdata.links"
            )
            .text-primary.q-pb-sm {{ $t("folder_access") }}
          .text-center.q-my-lg.q-pt-xl
            .text-h2 {{ $t("your-journey-so-far") }}

            .text-body1 {{ $t("number_beneficiaries") }}
            .row.q-col-gutter-sm.q-mt-lg
              .col
                FormInput(
                  type="number",
                  :name="$t('men')",
                  v-model="submission.formdata.number_beneficiaries.men",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
              .col
                FormInput(
                  type="number",
                  :name="$t('women')",
                  v-model="submission.formdata.number_beneficiaries.women",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
              .col
                FormInput(
                  type="number",
                  :name="$t('other')",
                  v-model="submission.formdata.number_beneficiaries.other",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )

            .row.q-col-gutter-sm.q-mt-lg
              .col
                FormInput(
                  type="number",
                  :name="$t('beneficiaries_dis')",
                  v-model="submission.formdata.beneficiaries_dis",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
            .row.q-col-gutter-sm.q-mt-lg
              .col
                FormInput(
                  type="number",
                  :name="$t('beneficiaries_indirect')",
                  v-model="submission.formdata.beneficiaries_indirect",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )

            .text-body1 {{ $t("beneficiaries_age") }}
            .row.q-col-gutter-sm.q-mt-lg
              .col-6.col-md
                FormInput(
                  type="number",
                  :name="$t('age_1')",
                  v-model="submission.formdata.age_beneficiaries['0-5']",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
              .col-6.col-md
                FormInput(
                  type="number",
                  :name="$t('age_2')",
                  v-model="submission.formdata.age_beneficiaries['6-12']",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
              .col-6.col-md
                FormInput(
                  type="number",
                  :name="$t('age_3')",
                  v-model="submission.formdata.age_beneficiaries['13-18']",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
              .col-6.col-md
                FormInput(
                  type="number",
                  :name="$t('age_4')",
                  v-model="submission.formdata.age_beneficiaries['19-30']",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
              .col-6.col-md
                FormInput(
                  type="number",
                  :name="$t('age_5')",
                  v-model="submission.formdata.age_beneficiaries['31-65']",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )
              .col-6.col-md
                FormInput(
                  type="number",
                  :name="$t('age_6')",
                  v-model="submission.formdata.age_beneficiaries['66']",
                  :rules="[(val) => val > 0 || this.$t('more-text-is-required')]"
                )

  .row.justify-center.q-col-gutter-sm.q-pb-xl.q-pa-dynamic(v-if="config")
    .col-sm-9.col-md-8.col-12
      .q-mt-sm
        q-field(
          outlined,
          color="black",
          no-error-icon,
          bg-color="white",
          :error="!hasAccepted",
          :bottom-slots="true"
        )
          template(v-slot:error)
            .float-right
              p.text-white
                q-icon.q-mr-xs(:name="matWarning", size="2em", color="white")
                span {{ $t("accept_terms_error") }}

          .full-width.q-py-sm
            .text-body1(style="margin-top: 5px") {{ $t("media_release_title") }}

            ul.q-pl-md
              li(
                v-for="(message, index) in Object.values(messages.media_release)",
                :key="index"
              ) {{ message }}

            .text-right
              i18n(path="agree_to", tag="span")
                span.on-left <a href="/policy" target="_blank">{{ $t("terms_privacy_policy") }}</a>
              q-checkbox(
                color="primary",
                v-model="accept",
                left-label,
                size="lg"
              )

            ul.q-pl-md
              li(
                v-for="(message, index) in Object.values(messages.media_release_consent)",
                :key="index"
              ) {{ message }}

            .text-right
              i18n(path="agree_to_nolink", tag="span")
              q-checkbox(
                color="primary",
                v-model="acceptconsent",
                left-label,
                size="lg"
              )

            .text-body1(style="margin-top: 5px") {{ $t("research_subtitle") }}

            ul.q-pl-md
              li(
                v-for="(message, index) in Object.values(messages.research_terms)",
                :key="index"
              ) {{ message }}

            .text-right
              i18n(path="agree_to", tag="span")
                span.on-left <a href="/research" target="_blank">{{ $t("terms_research_policy") }}</a>
              q-checkbox(
                color="primary",
                v-model="acceptresearch",
                left-label,
                size="lg"
              )
          //- template(v-slot:prepend) 
          //-   q-icon(:name="matPolicy" size="sm" color="primary")
      .row.q-mt-xl.justify-between
        .col-md-auto.col-6
          q-btn(
            to="/submission",
            :icon="matWest",
            size="lg",
            flat,
            rounded,
            dense,
            no-caps,
            color="white"
          ) {{ $t("back") }}
        .col-md-auto.col-6
          q-btn(
            color="accent",
            text-color="black",
            :icon-right="matNorthEast",
            unelevated,
            no-caps,
            size="lg",
            align="between",
            style="width: 300px; max-width: 100%",
            :label="$t('submit')",
            type="Submit"
          )
  .fixed-full.bg-primary.z-max(v-if="uploading")
    .dimmed
      .dimmed
        .z-max.absolute-center.text-white.text-center
          div(v-if="!checking")
            .text-h3(style="max-width: 700px; width: 80vw") {{ $t("uploading") }}
            .text-body1.q-mt-md {{ $t("dont_close") }}
            q-linear-progress.q-my-lg(size="15px", :value="progress")
          div(v-if="checking && !success")
            .text-h3(style="max-width: 700px; width: 80vw") {{ $t("processing") }}
            q-linear-progress.q-my-lg(size="15px", indeterminate)
          div(v-if="success && checking")
            .text-h3(style="max-width: 700px; width: 80vw") {{ $t("submission-complete") }}
            q-btn.q-mt-lg.text-black(
              to="/submission#my",
              color="accent",
              unelevated,
              size="lg",
              no-caps
            ) {{ $t("return-to-my-journey") }}
</template>

<script>
/* eslint-disable no-undef */
import { mapActions, mapState, mapGetters } from "vuex";
import PhaseSubmission from "@/components/forms/PhaseSubmission";
// const regions = require("@/ifrclist.json");
import { ifrcList } from "ifrc-list";
const langmap = require("@/language-mapping-list");
import each from "lodash/each";
import map from "lodash/map";
import zipObject from "lodash/zipObject";
import fill from "lodash/fill";
import find from "lodash/find";
import size from "lodash/size";
import uniq from "lodash/uniq";
import reject from "lodash/reject";
import sortBy from "lodash/sortBy";
import compact from "lodash/compact";
// import filter from "lodash/filter";
import Language from "@/mixins/Language";
import FormInput from "@/components/FormInput";
import { EventBus } from "@/event-bus";
import { fabYoutube } from "@quasar/extras/fontawesome-v5";
import langs from "../langs";
var { allCountries } = require("@/country-telephone-data-ifrc");
// const formoptions = require("../formoptions.json");

import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  name: "SubmissionForm",
  mixins: [Language],
  components: {
    PhaseSubmission,
    FormInput,
  },
  props: ["selectedphase"],
  watch: {
    selectedTags: {
      deep: true,

      handler() {
        let arr = [];
        for (const [k, v] of Object.entries(this.selectedTags)) {
          if (v) arr.push(k);
        }

        this.submission.tags = arr;
      },
    },
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$q.loading.show({
            delay: 400, // ms
          });
        } else {
          this.$q.loading.hide();
        }
      },
    },
  },
  async created() {
    this.loading = true;
    this.fabYouTube = fabYoutube;
    try {
      await Promise.all([this.initConfig(), this.getSubmissions(true)]);
    } catch (e) {
      console.log("Error, rerouting");
      this.$router.replace("/submission");
    }

    //check if they already have a submission

    if (!this.canSubmit()(this.selectedphase)) {
      this.$router.replace("/submission");
    }

    EventBus.$on("upload_progress", this.updateProgress);

    if (this.config.tag_options) {
      this.selectedTags = zipObject(
        Object.keys(this.config.tag_options),
        fill(Array(size(this.config.tag_options)), false)
      );
    }

    this.loading = false;
  },
  computed: {
    collectWhatsApp() {
      // console.log(this.config.phases);
      if (find(this.config.phases, { code: this.phase }).collectWhatsApp)
        return true;
      else return false;
    },
    collectReporting() {
      if (find(this.config.phases, { code: this.phase }).collectReporting)
        return true;
      else return false;
    },
    collectPayment() {
      if (find(this.config.phases, { code: this.phase }).collectPayment)
        return true;
      else return false;
    },
    telMask() {
      return this.telCode?.format.replaceAll(".", "#");
    },
    telCodes() {
      return sortBy(
        map(allCountries, (f) => {
          return {
            ...f,
            label: `${f.name} +${f.dialCode}`,
          };
        }),
        "name"
      );
    },
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    hasTags() {
      return this.submission.tags.length && this.submission.tags.length < 4;
    },
    hasAccepted() {
      return this.accept && this.acceptresearch && this.acceptconsent;
    },
    phase() {
      // USE ROUTE IF SET TO CALCULATE PHASE:
      if (this.selectedphase) return this.selectedphase;
      else return this.config.current_phase;
    },
    recordingLangs() {
      // const ph = find(this.config.phases,{code:this.config.current_phase});

      let lngs = [];
      for (let l of this.config.target_language)
        lngs.push({
          label: this.codeToLang(l),
          value: l,
        });

      return lngs;
    },
    commsLangs() {
      let lngs = [];
      for (let l of langs)
        lngs.push({
          label: this.codeToLang(l),
          value: l,
        });
      return lngs;
    },
    languages() {
      let tmp = [];
      each(langmap, (a, k) => {
        if (k.length == 2)
          tmp.push({
            value: k,
            label: a.nativeName,
          });
      });
      return tmp;
    },
    regions() {
      return map(ifrcList, (r) => {
        return {
          value: r.code,
          flag: r.flag,
          label: r.name,
        };
      });
    },
    tagOptionsSecondary() {
      return reject(this.tagOptions, { value: this.submission.oneTag });
    },
    tagOptions() {
      return map(this.config.tag_options, (k, v) => {
        return {
          label: this.$t("tags." + v),
          value: v,
          icon: this.getTagIcon(v),
        };
      });
    },
    ...mapState(["config"]),
    ...mapGetters(["getTagIcon"]),
  },
  methods: {
    validationError(el) {
      // console.log(el);
      // const bb = el.$el.getBoundingClientRect();
      // const body = document.body.getBoundingClientRect();
      // console.log(bb.top - body.top + 100);
      // window.scrollTo(0, bb.top - body.top + 100);
      // el.$el.scrollIntoView();

      const target = getScrollTarget(el.$el);

      // console.log(el);
      const offset =
        el.$el.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        150;
      const duration = 1000;

      // console.log(offset);

      setScrollPosition(target, offset, duration);
    },
    filterDialCode(val, update) {
      if (val === "") {
        update(() => {
          this.filteredCodes = this.telCodes;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        // console.log(val);
        // console.log(this.telCodes);
        this.filteredCodes = this.telCodes.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    validateService(val) {
      // console.log(this.submission.formdata.nonwhatsapp);
      return Boolean(this.submission.formdata.nonwhatsapp === false || val);
    },
    validateNonWhatsApp(val) {
      // console.log(val);
      return this.submission.formdata.nonwhatsapp === false || val.length > 1;
    },
    validateWhatsApp(val) {
      // console.log(val);

      return (
        this.submission.formdata.nonwhatsapp === true ||
        (isFinite(val) && val.length > 1)
      );
    },
    validateWhatsAppCode(val) {
      // console.log(val);
      return Boolean(this.submission.formdata.nonwhatsapp === true || val);
    },
    updateProgress(progress) {
      this.progress = progress;
    },
    async onSubmit() {
      try {
        // if (!(await this.$refs.formInput).validateMe()) {
        //   this.uploading = false;
        //   return;
        // }

        // console.log(this.submission);
        // console.log(this.files);

        // console.log(this.hasAccepted);

        //has accepted:
        if (this.hasAccepted !== true) {
          this.uploading = false;
          return;
        }

        //has tags:
        if (this.$refs.tags && this.$refs.tags.error) {
          this.uploading = false;
          return;
        }

        //check files are different:
        let names = compact(map(this.files, "name"));
        // console.log(uniq(names));
        // console.log(names);
        // console.log(this.submission.formdata.textEntry);

        //if there is no video OR text submission, return:
        // if (this.names.length === 0) {
        //   this.uploading = false;
        //   return;
        // }

        if (uniq(names).length !== names.length) {
          //not unique files:
          this.uploading = false;

          this.$q.notify({
            type: "negative",
            message: this.$t("resubmit_message"),
          });
          return;
        }

        this.uploading = true;

        // this.submission.language = this.submission.language.substr(0,2);

        //HARDCODE LANGUAGE:
        // this.submission.language = "en";
        // this.submission.commslanguage = "en";

        await this.submitAction({
          submission: this.submission,
          files: this.files,
          phase: this.phase,
        });

        this.checking = true;

        //artificial delay to wait for the firebase function to set new state:
        setTimeout(async () => {
          await this.getSubmissions(true);
          //display success screen:
          this.success = true;
          // this.$router.replace("/submission?success");
        }, 5000);
      } catch (e) {
        console.log(e);
        //report failure
        this.showError(this.$t("submission_failed"));
        this.uploading = false;
        this.checking = false;
        this.success = false;
      }
    },
    onRejected() {
      this.$q.notify({
        color: "secondary",
        textColor: "white",
        icon: this.matWarning,
        message: this.$t("video_size_warning"),
      });
    },
    ...mapActions(["submitAction", "initConfig", "getSubmissions"]),
    ...mapGetters(["canSubmit"]),
  },
  data() {
    return {
      selectedTags: {},
      submission: {
        tags: [],
        formdata: {
          nonwhatsapp: false,
          telCode: null,
          nonwhatsappident: "",
          whatsapp: "",
          number_beneficiaries: {},
          age_beneficiaries: {},
          projectname: "",
          projectdescription: "",
          testemony_team: "",
          testemony_beneficiary: "",
        },
        oneTag: null,
      },
      files: [null, null, null, null, null],
      accept: false,
      acceptresearch: false,
      acceptconsent: false,
      uploading: false,
      checking: false,
      success: false,
      progress: 0,
      loading: true,
      filteredCodes: [],
      alternativeWA: [
        { value: "telegram", label: this.$t("telegram") },
        { value: "wechat", label: this.$t("wechat") },
      ],
    };
  },
};
</script>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
/* global styles */
</style>

<style lang="scss" scoped>
.info {
  font-size: 1.5em;
}

.flag {
  font-size: 2.5em;
}
</style>

<style lang="scss">
@import "@/styles/quasar.variables.scss";
// .q-chip--selected .q-chip__icon img {
// background:green !important;
//   filter: invert(1) !important;
//   display: none;
// }
.q-chip--selected {
  img.q-chip__icon {
    // background:green !important;
    filter: brightness(100);
    // display: none;
  }
}

.q-field--outlined .q-field__control:before {
  border-color: $primary !important;
}

.dimout {
  opacity: 0.4;
}
</style>
